<template>
  <div class="sidebar">
    <router-link :to="{ name: 'home' }" class="archive-link">
      <span class="material-symbols-outlined google-icon">home</span>
      <small>{{ $t("navigation.home") }}</small>
    </router-link>
    <router-link :to="{ name: 'search' }" class="archive-link">
      <span class="material-symbols-outlined google-icon">search</span>
      <small>{{ $t("navigation.search") }}</small>
    </router-link>
    <router-link :to="{ name: 'archive' }" class="archive-link">
      <span class="material-symbols-outlined google-icon">restore_page</span>
      <small>{{ $t("navigation.deleted") }}</small>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SideNav",
  setup() {
    return {
    };
  },
};
</script>

<style scoped>

.beta-label {
  font-size: 0.64rem;
  line-height: 1.875rem;
  font-optical-sizing: true;
  font-weight: 600;
  vertical-align: super;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  height: 100%;
}

.archive-link {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: .1rem;
}

.close-btn {
  align-self: flex-end;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
</style>
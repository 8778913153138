<template>
  <div class="karakutu-container" :class="{ 'with-secondary-nav': isSecondaryNavVisible }"
    :style="{ '--header-height': isSearchRoute ? '6.8rem' : '3rem' }">
    <header class="karakutu-header">
      <AppHeader />
    </header>
    <nav class="side-nav">
      <SideNav />
      <div class="icon-wrapper">
        <a href="https://x.com/karakutu_xyz" target="_blank">
          <img :src="twitter" alt="Twitter" class="footer-icon" />
        </a>
        <a href="https://mastodon.social/@karakutu" target="_blank">
          <img :src="mastodon" alt="Mastodon" class="footer-icon" />
        </a>
        <a href="https://telegram.me/" target="_blank">
          <img :src="telegram" alt="Telegram" class="footer-icon" />
        </a>
        <a href="https://discord.com/" target="_blank">
          <img :src="discord" alt="Discord" class="footer-icon" />
        </a>
      </div>
    </nav>
    <aside class="secondary-nav" v-show="isSecondaryNavVisible">
      <FiltersCard />
    </aside>
    <main class="karakutu-content">
      <slot></slot>
    </main>
    <ScrollTopButton />
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useUserStore } from "../stores/user";
import AppHeader from "../components/AppHeader.vue";
import FiltersCard from "../components/FiltersCard.vue";
import ScrollTopButton from "../components/ScrollTopButton.vue";
import SideNav from "../components/SideNav.vue";
import discord from "../assets/footer/discord.svg";
import mastodon from "../assets/footer/mastodon.svg";
import telegram from "../assets/footer/telegram.svg";
import twitter from "../assets/footer/x.svg";

export default {
  components: { AppHeader, ScrollTopButton, SideNav, FiltersCard },
  name: "GridLayout",
  setup() {
    const route = useRoute();
    const userStore = useUserStore();
    const isSearchRoute = computed(() => route.name === "search");
    const isSecondaryNavVisible = computed(() => {
      return userStore.getIsSecondaryNavVisible && isSearchRoute.value;
    });
    const toggleSecondaryNav = () => {
      if (isSearchRoute) {
        userStore.toggleSecondaryNav();
      }
    };
    return {
      discord,
      mastodon,
      telegram,
      twitter,
      isSearchRoute,
      isSecondaryNavVisible,
      toggleSecondaryNav,
    };
  },
};
</script>

<style scoped>
.app-container {
  background-image:
    repeating-linear-gradient(to bottom,
      #e0e0e0,
      #e0e0e0 var(--border-width),
      transparent var(--border-width),
      transparent 1.5rem),
    repeating-linear-gradient(to right,
      #e0e0e0,
      #e0e0e0 var(--border-width),
      transparent var(--border-width),
      transparent 6.25%);
  height: fit-content;
  width: 100%;
  position: relative;
}

.diagonal-line {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom left,
      transparent calc(50% - var(--border-width)),
      #e0e0e0 calc(50% - var(--border-width)),
      #e0e0e0 calc(50% + var(--border-width)),
      transparent calc(50% + var(--border-width)));
}

.icon-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: .4rem;
  align-content: flex-end;
  margin-top: auto;
}

.footer-icon {
  width: var(--font-size-h5);
  height: var(--font-size-h5);
  color: var(--link-color);
  fill: var(--link-color);
}
</style>
export default {
  appHeader: {
    archive: "Archiv",
  },
  connectButton: {
    connect: "Einloggen",
  },
  search: {
    addFilter: "Filter hinzufügen",
    placeholder: "Suche",
    searching: "Suche...",
    matchQuery: "Es wurden {total} Ergebnisse für {query} gefunden.",
    match: "{total} Ergebnisse gefunden.",
    noMatch: "Keine Ergebnisse gefunden.",
    sort: "Sortieren",
    filter: {
      title: "Suche filtern",
      author: "Autor",
      newspaper: "Zeitung",
      status: "Status",
      locations: "Wo?",
      persons: "Wer?",
      organizations: "Organisationen",
      startDate: "Startdatum",
      endDate: "Enddatum",
      apply: "Filter anwenden",
      all: "Alle",
      daterange: "Datumsbereich",
      language: "Sprache",
    },
    loading: "Suche...",
  },
  sort: {
    relevance: "Relevanz",
    date_desc: "Neueste zuerst",
    date_asc: "Älteste zuerst",
  },
  haber: {
    recordSource: "Quellenlink:",
    recordDate: "Aufnahmedatum:",
    recordStatus: "Aufnahmestatus:",
    category: "Kategorie:",
    updated: "Aktualisiert:",
    tags: "Schlagwörter:",
    oldRecords: "Frühere Aufnahmen",
    compareRecords: "Aufnahmen Vergleichen",
    compare: "Vergleichen",
    archive: "Nachricht Archivieren",
    otherNews: "Andere Artikel des Autors",
  },
  status: {
    Initial: "Keine Änderungen",
    Changed: "Aktualisiert",
    DeadLink: "Gelöscht",
    Redirected: "Weitergeleitet",
  },
  navigation: {
    home: "Startseite",
    search: "Suche",
    deleted: "Gelöscht",
  },
  dateShortcuts: {
    last24hours: "24 Std",
    lastWeek: "7 Tg",
    lastMonth: "1 Mo",
    lastYear: "1 Jr",
  },
  tableColumns: {
    id: "Eintrag",
    link: "Titel",
    newspaper: "Zeitung",
    authors: "Autoren",
    formattedPublishDate: "Veröffentlichungsdatum",
    checkDate: "Zuletzt gesehen",
  },
};

<template>
  <div class="chart-container" ref="chartRef">
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed, onMounted } from "vue";
import Plotly from "plotly.js-basic-dist-min";
import { useI18n } from "vue-i18n";
import { useSearchStore } from "../stores/search";

export default defineComponent({
  name: "SearchHistogram",
  setup() {
    const searchStore = useSearchStore();
    const chartTraces = computed(() => {
      const aggregations = searchStore.aggregations;
      if (!aggregations || !aggregations.publish_dates) return null;
      const dates = aggregations.publish_dates.map((bucket) => bucket.key_as_string);
      const counts = aggregations.publish_dates.map((bucket) => bucket.doc_count);
      return [
        {
          x: dates,
          y: counts,
          type: "bar",
          marker: {
            color: "#212121",
            opacity: 0.75,
          },
        },
      ];
    });
    const chartRef = ref(null);
    const { locale } = useI18n();

    const updateChart = async (traces) => {
      const Plotly = searchStore.plotly;
      const dateRange =
        (new Date(traces[0]?.x[traces[0].x.length - 1]) -
          new Date(traces[0]?.x[0])) /
        (1000 * 60 * 60 * 24);
      let dtickValue = "1D";
      let tickformatValue = "%d %b %Y";
      let tickvals = null;
      let ticktext = null;
      if (dateRange === 0) {
        dtickValue = null;
        tickvals = [traces[0]?.x[0]];
      } else if (dateRange < 15) {
        tickvals = traces[0]?.x.slice(0, dateRange + 1);
        ticktext = tickvals.map((date) =>
          new Date(date).toLocaleDateString(locale.value, {
            day: "2-digit",
            month: "short",
          }),
        );
        dtickValue = null;
      } else if (dateRange > 3 * 365) {
        dtickValue = "1Y";
        tickformatValue = "%Y";
      } else if (dateRange > 365) {
        dtickValue = "1M";
        tickvals = traces[0]?.x.filter((date, index) => index % 90 === 0);
        ticktext = tickvals.map((date) =>
          new Date(date).toLocaleDateString(locale.value, {
            month: "short",
            year: "numeric",
          }),
        );
        tickformatValue = "%b %Y";
      } else if (dateRange > 90) {
        dtickValue = "1M";
        tickvals = traces[0]?.x.filter((date, index) => index % 30 === 0);
        ticktext = tickvals.map((date) =>
          new Date(date).toLocaleDateString(locale.value, {
            month: "short",
            year: "numeric",
          }),
        );
        tickformatValue = "%b %Y";
      } else if (dateRange > 14) {
        dtickValue = "7D";
        tickvals = traces[0]?.x.filter((date, index) => index % 7 === 0);
        ticktext = tickvals.map((date) =>
          new Date(date).toLocaleDateString(locale.value, {
            day: "2-digit",
            month: "short",
          }),
        );
      }
      const layout = {
        xaxis: {
          tickfont: {
            family: "Alegreye",
            size: 15.2,
            color: "#000",
          },
          ticktext: ticktext,
          tickangle: 0,
          tickformat: tickformatValue,
          gridcolor: "#4a4a4a20",
          gridwidth: 1,
          zerolinecolor: "#4a4a4a20",
          zerolinewidth: 1,
          tickmode: tickvals ? "array" : "auto",
          tickvals: tickvals,
          dtick: dtickValue,
          tickpad: 9.5,
          side: "bottom",
          anchor: "y",
          fixedrange: false,
        },
        yaxis: {
          tickfont: {
            family: "Piazzola",
            size: 12,
            color: "#000",
          },
          tickformat: "d",
          gridcolor: "#4a4a4a20",
          gridwidth: 1,
          zerolinecolor: "#4a4a4a20",
          zerolinewidth: 1,
          nticks: 2,
          side: "left",
          fixedrange: true,
          tickpadding: 10,
        },
        margin: {
          l: 50,
          r: 0,
          b: 28.5,
          t: 0,
          pad: 9,
        },
        height: 100,
        autosize: true,
        responsive: true,
        dragmode: "none",
        hovermode: "x",
        hoverlabel: {
          font: {
            family: "Piazzola",
            size: 14,
            color: "#fff",
          },
        },
        hovertemplate: "%{x|%d %B %Y}",
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        plot_bgcolor: "rgba(0, 0, 0, 0)"
      };
      const config = {
        displaylogo: false,
        modeBarButtonsToRemove: ["select2d", "lasso2d"],
        responsive: true,
        toImageButtonOptions: {
          format: "png",
          filename: "karakutu",
          height: 600,
          width: 800,
          scale: 1,
        },
        showAxisRangeEntryBoxes: true,
      };
      Plotly.react(chartRef.value, traces, layout, config).then((plot) => {
        plot.on("plotly_relayout", handleRelayout);
      });
    };

    const handleRelayout = (eventData) => {
      if (eventData["xaxis.range[0]"] && eventData["xaxis.range[1]"]) {
        const startDate = new Date(eventData["xaxis.range[0]"])
          .toISOString()
          .split("T")[0];
        const endDate = new Date(eventData["xaxis.range[1]"])
          .toISOString()
          .split("T")[0];
        searchStore.applyFilters({
          publish_date_start: startDate,
          publish_date_end: endDate,
        });
      }
    };

    onMounted(() => {
      if (chartTraces.value && chartTraces.value.length > 0) {
        updateChart(chartTraces.value);
      }
    });

    watch(
      () => chartTraces.value,
      (newTraces) => {
        if (chartTraces.value && chartTraces.value.length > 0) {
          updateChart(newTraces);
        };
      },
      { deep: true },
    );

    watch(
      () => locale.value,
      () => {
        if (chartTraces.value && chartTraces.value.length > 0) {
          updateChart(chartTraces.value);
        }
      },
    );

    return {
      chartTraces,
      chartRef,
    };
  },
});
</script>

<style scoped>
.chart-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 86.5;
}
</style>
export default {
  appHeader: {
    archive: "Archive",
  },
  connectButton: {
    connect: "Login",
  },
  search: {
    addFilter: "Add Filter",
    placeholder: "Search",
    searching: "Searching...",
    matchQuery: "Found {total} results matching {query}.",
    match: "Found {total} results.",
    noMatch: "No results found.",
    sort: "Sort",
    filter: {
      title: "Filter your search",
      author: "Author",
      newspaper: "Newspaper",
      status: "Status",
      locations: "Where?",
      persons: "Who?",
      organizations: "Organizations",
      startDate: "Start Date",
      endDate: "End Date",
      apply: "Apply Filter",
      all: "All",
      daterange: "Date Range",
      more: "More",
      language: "Language",
    },
    loading: "Searching...",
  },
  sort: {
    relevance: "Relevance",
    date_desc: "Newest",
    date_asc: "Oldest",
  },
  haber: {
    recordSource: "Source Link:",
    recordDate: "Record Date:",
    recordStatus: "Record Status:",
    category: "Category:",
    updated: "Updated:",
    tags: "Tags:",
    oldRecords: "Past Records",
    compareRecords: "Compare Records",
    compare: "Compare",
    archive: "Archive the News",
    otherNews: "Other Articles by the Author",
  },
  status: {
    Initial: "No changes",
    Changed: "Updated",
    DeadLink: "Deleted",
    Redirected: "Redirected",
  },
  navigation: {
    home: "Home",
    search: "Search",
    deleted: "Deleted",
  },
  dateShortcuts: {
    last24hours: "24h",
    lastWeek: "7d",
    lastMonth: "1m",
    lastYear: "1y",
  },
  tableColumns: {
    id: "Record",
    link: "Title",
    newspaper: "Newspaper",
    authors: "Authors",
    formattedPublishDate: "Publication Date",
    checkDate: "Last Seen",
  },
};

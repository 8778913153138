<template>
  <div class="karakutu-header-wrapper">
    <div class="karakutu-main-header">
      <div class="karakutu-header-left">
        <RouterLink to="/search" class="title-link">
          <h2 class="karakutu-title">Kara kutu<span class="beta-label">beta</span></h2>
        </RouterLink>
      </div>
      <div class="karakutu-header-center">
        <SearchBar @select-suggestion="selectSuggestion" />
      </div>
      <div class="karakutu-header-right">
        <button class="menu-icon" @click="toggleMenu">
          <span class="material-symbols-outlined">menu</span>
        </button>
        <nav :class="{ 'show-menu': showMenu }">
          <LanguageButton />
          <ConnectButton />
        </nav>
      </div>
    </div>
    <div v-if="isSearchRoute" class="karakutu-secondary-header">
      <div class="nav-btn">
        <button @click="toggleSecondaryNav" class="action-button">
          <span class="button-content" v-if="!isConnected">
            <span class="material-symbols-outlined">tune</span>
            {{ $t("search.addFilter") }}
          </span>
        </button>
      </div>
       <div v-if="appliedFilters.length > 0" class="applied-filters">
         <button v-for="(filter, index) in appliedFilters" :key="index" class="filter-tag"
           @click.stop="removeFilter(filter.key)">
           <small class="filter-value">{{ filter.value }}</small>
           <small class="remove-btn">x</small>
         </button>
       </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import SearchBar from "./SearchBar.vue";
import LanguageButton from "./LanguageButton.vue";
import ConnectButton from "./ConnectButton.vue";
import { useContractStore } from "../stores/contract";
import { useUserStore } from "../stores/user";
import { useSearchStore } from '../stores/search';
import { getLanguageName } from "../utils"

export default {
  name: "AppHeader",
  components: {
    ConnectButton,
    LanguageButton,
    SearchBar,
  },
  setup() {
    const userStore = useUserStore();
    const contractStore = useContractStore();
    const searchStore = useSearchStore();
    const router = useRouter();
    const route = useRoute();
    const isConnected = computed(() => contractStore.isConnected);
    const toggleSecondaryNav = () => {
      if (route.name === "search") {
        userStore.toggleSecondaryNav();
      }
    };
    const searchQuery = ref("");
    const isSearchRoute = computed(() => route.name === "search");
    const selectSuggestion = (suggestion) => {
      const routeParams = { id: suggestion.id };
      router.push({ name: "haber", params: routeParams });
    };
    const showMenu = ref(false);
    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };
    const filters = computed(() => searchStore.filters)
    const appliedFilters = computed(() => {
      const filterList = [];
      if (filters.value.newspaper) {
        filterList.push({
          label: "Newspaper",
          value: filters.value.newspaper,
          key: "newspaper",
        });
      }
      if (filters.value.author) {
        filterList.push({
          label: "Author",
          value: filters.value.author,
          key: "author",
        });
      }
      if (filters.value.language_code) {
        filterList.push({
          label: "Language",
          value: getLanguageName(filters.value.language_code),
          key: "language_code",
        });
      }

      if (filters.value.status) {
        filterList.push({
          label: "Status",
          value: filters.value.status,
          key: "status",
        });
      }
      return filterList;
    });

    const removeFilter = (filterKey) => {
      searchStore.applyFilters({ [filterKey]: null })
      const updatedQuery = { ...router.currentRoute.value.query };
      delete updatedQuery[filterKey];
      router.replace({ query: updatedQuery });
    };

    return {
      appliedFilters,
      searchQuery,
      selectSuggestion,
      showMenu,
      toggleMenu,
      isSearchRoute,
      isConnected,
      toggleSecondaryNav,
      removeFilter,
      getLanguageName
    };
  },
};
</script>

<style scoped>
.karakutu-title {
  margin: 0;
}

.beta-label {
  font-size: 0.64rem;
  line-height: 0.8rem;
  font-optical-sizing: true;
  font-weight: 600;
  vertical-align: super;
}

.archive-link {
  background-color: transparent;
  color: rgb(34, 35, 38);
  cursor: pointer;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: "Alegreya", serif;
  font-weight: 400;
  gap: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.google-icon {
  color: rgb(34, 35, 38);
  font-size: 1.25rem;
  font-weight: 200;
}

.archive-link {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: rgb(34, 35, 38);
  cursor: pointer;
  font-size: 1rem;
  gap: 0.25rem;
  padding: 0 0.5rem;
  line-height: 1.5rem;
}

.menu-icon {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}


.title-link {
  text-decoration: none;
}

nav {
  display: flex;
  align-items: center;
}

.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  border: 1px solid #ccc;
  border-radius: 1.5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  padding: 0 .75rem;
  background-color: white;
}

.action-button:hover {
  background-color: #222326; /* Dark background on hover */
  color: white; /* White text on hover */
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-family: "Alegreya", serif;
  font-weight: 400;
}


@media (max-width: 1280px) {
  .menu-icon {
    display: inline-flex;
    font-size: 1.5rem;
    color: rgb(34, 35, 38);
  }

  .karakutu-header-right nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 8px;
    z-index: 10;
  }

  .karakutu-header-right nav.show-menu {
    display: flex;
  }

  .archive-link,
  .google-icon,
  .beta-label {
    font-size: 0.9rem;
  }
}

@media (max-width: 720px) {
  .karakutu-header-center {
    display: none;
  }

  .karakutu-header-right {
    width: fit-content;
  }

  .karakutu-header-left {
    width: fit-content;
  }
}
</style>
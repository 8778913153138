<template>
  <div class="nav-btn">
    <ConnectDialog v-show="showConnectDialog"/>
    <button @click.stop="isConnected ? openDropdown() : openConnectDialog()">
      <span class="button-content" v-if="!isConnected">
        <span class="material-symbols-outlined">account_balance_wallet</span>
        {{ $t("connectButton.connect") }}
      </span>
      <span v-else> {{ shortAddress }}</span>
    </button>
    <div ref="dropdown" v-if="isDropdownOpen" class="dropdown-menu">
        <div class="dropdown-item">
          <span><h5>Signed in as</h5></span>
          <p>{{ currentAccount.address }}</p>
        </div>
        <div class="dropdown-item">
          <span><h5>Network</h5></span>
          <p>Arweave</p>
        </div>
        <div class="dropdown-item">
          <button @click.stop="disconnect" no-caps>
            <span class="material-symbols-outlined">power_settings_new</span>
            Disconnect
          </button>
        </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, ref, onMounted, onUnmounted } from 'vue';
  import ConnectDialog from './ConnectDialog.vue';
  import { useContractStore } from "../stores/contract";

  export default defineComponent({
    components: {
      ConnectDialog
    },

    setup() {
      const contractStore = useContractStore();
      const isConnected = computed(() => contractStore.isConnected);
      const currentAccount = computed(() => contractStore.currentAccount);
      const showConnectDialog = ref(false);
      const dropdown = ref(null);
      const isDropdownOpen = ref(false);

      const openConnectDialog = async () => {
        showConnectDialog.value = true;
      };

      const openDropdown = () => {
        isDropdownOpen.value = true;
      };

      const handleClickOutside = (event) => {
        if (dropdown.value && !dropdown.value.contains(event.target)) {
          isDropdownOpen.value = false;
        }
        if (showConnectDialog.value) {
          showConnectDialog.value = false;
        }
      };

      onMounted(async () => {
        document.addEventListener('click', handleClickOutside);
        //await contractStore.syncWallet();
      });

      onUnmounted(() => {
        document.removeEventListener('click', handleClickOutside);
      });

      const disconnect = async () => {
        isDropdownOpen.value = false;
        await contractStore.disconnectWallet();
      };

      const shortAddress = computed(() => {
        if (isConnected.value && currentAccount.value && currentAccount.value.address) {
          const address = currentAccount.value.address;
          if (address.length >= 10) {
            const firstPart = address.substring(0, 6);
            const lastPart = address.substring(address.length - 4);
            return `${firstPart}....${lastPart}`;
          }
          return address;
        }
        return '';
      });

      return {
        isConnected,
        isDropdownOpen,
        disconnect,
        currentAccount,
        showConnectDialog,
        openConnectDialog,
        openDropdown,
        dropdown,
        shortAddress,
      }
    }
  })
</script>

<style scoped>
.nav-btn {
  padding-left: .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  border: 1px solid #ccc;
  border-radius: 1.5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  padding: 0 .75rem;
  background-color: white;
}

button:hover {
  background-color: #222326; /* Dark background on hover */
  color: white; /* White text on hover */
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-family: "Alegreya", serif;
  font-weight: 400;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: -.375rem;
  background-color: white; /* Same as button background */
  color: #000; /* Text color */
  border: 1px solid #ccc; /* Softer border */
  border-radius: 14px;
  z-index: 1000;
  width: 12rem; /* Fixed width for dropdown */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 0.75rem 0; /* Padding inside dropdown */
}

.dropdown-item {
  padding: 0.5rem; /* Padding for items */
  transition: background-color 0.2s ease;
}

.dropdown-item h5{
  margin: 0;
}

.dropdown-item button {
  justify-self: center;
}
</style>

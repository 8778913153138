export default {
  connectButton: {
    connect: "Giriş Yap",
  },
  search: {
    addFilter: "Filtre Ekle",
    placeholder: "Araştır",
    searching: "Aranıyor...",
    matchQuery: "Aramanız {query} ile eşleşen {total} sonuç bulundu.",
    match: "{total} sonuç bulundu.",
    noMatch: "Hiç sonuç bulunamadı.",
    sort: "Sıralama",
    filter: {
      title: "Aramanı filtrele",
      author: "Yazar",
      newspaper: "Gazete",
      status: "Kayıt Durumu",
      locations: "Nerede?",
      persons: "Kim?",
      organizations: "Kurumlar",
      startDate: "Başlangıç tarihi",
      endDate: "Bitiş tarihi",
      apply: "Filtreyi uygula",
      all: "Hepsi",
      more: "Daha",
      daterange: "Tarih aralığı",
      language: "Dil",
    },
    loading: "Aranıyor...",
  },
  sort: {
    relevance: "Alaka",
    date_desc: "En Yeni",
    date_asc: "En Eski",
  },
  haber: {
    recordSource: "Kaynak Link:",
    recordDate: "Kayıt tarihi:",
    recordStatus: "Kayıt durumu:",
    category: "Kategori:",
    updated: "Güncelleme:",
    tags: "Etiketler:",
    oldRecords: "Geçmiş Kayıtlar",
    compareRecords: "Kayıtları Karşılaştır",
    compare: "Karşılaştır",
    archive: "Haberi Arşivle",
    otherNews: "Yazarın Diğer Yazıları",
    relatedNews: "İlgili Haberler",
  },
  status: {
    Initial: "Değişiklik yok",
    Changed: "Güncellendi",
    DeadLink: "Silindi",
    Redirected: "Yönlendirildi",
  },
  navigation: {
    home: "Ana Sayfa",
    search: "Arama",
    deleted: "Silinenler",
  },
  dateShortcuts: {
    last24hours: "24 saat",
    lastWeek: "7 gün",
    lastMonth: "1 ay",
    lastYear: "1 yıl",
  },
  tableColumns: {
    id: "Kayıt",
    link: "Başlık",
    newspaper: "Gazete",
    authors: "Yazarlar",
    formattedPublishDate: "Yayın tarihi",
    checkDate: "Son görülme",
  },
};

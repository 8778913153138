<template>
  <div class="filters-card">
    <div class="filters-header">
      <button @click="removeAllFilters" class="header-button">
        <span class="button-content">
          <span class="material-symbols-outlined google-icon"> reset_settings </span>
        </span>
      </button>
      <button class="header-button" @click="toggleSecondaryNav">
        <span class="button-content">
        <span class="material-symbols-outlined google-icon">
          close
        </span>
      </span>

      </button>
    </div>
    <div class="filter-section date-filter">
      <div class="filter-item">
        <h4 @click="toggleSection('dates')">
          {{ $t("search.filter.daterange") }}
          <span class="material-symbols-outlined google-icon arrow" :class="{ open: isSectionVisible.dates }">
            keyboard_arrow_down
          </span>
        </h4>
        <SearchHistogram/>
        <div v-show="isSectionVisible.dates" class="expandable-panel">
          <Datepicker v-model="dateRange" range format="dd-MM-yyyy"
            :placeholder="$t('search.filter.daterange')" class="filter-input" :not-after="new Date()" locale="tr" />
          <div class="date-shortcut-buttons">
            <button class="shortcut-btn" @click="setDateRange('last24hours')"><small>{{ $t("dateShortcuts.last24hours") }}</small></button>
            <button class="shortcut-btn" @click="setDateRange('lastWeek')"><small>{{ $t("dateShortcuts.lastWeek") }}</small></button>
            <button class="shortcut-btn" @click="setDateRange('lastMonth')"><small>{{ $t("dateShortcuts.lastMonth") }}</small></button>
            <button class="shortcut-btn" @click="setDateRange('lastYear')"><small>{{ $t("dateShortcuts.lastYear") }}</small></button>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div class="filter-item">
        <h4 @click="toggleSection('status')">
          {{ $t("search.filter.status") }}
          <span class="material-symbols-outlined google-icon arrow" :class="{ open: isSectionVisible.status }">
            keyboard_arrow_down
          </span>
        </h4>
        <div v-show="isSectionVisible.status" class="expandable-panel">
          <div class="authors-buttons">
            <div v-if="topStatuses.length > 0">
              <button v-for="(status, index) in topStatuses" :key="index" @click="selectFilter({ status: status.key })"
                :class="{ active: filters.status === status.key }">
                <span class="author-name">{{ $t(`status.${status.key}`) }}</span>
                <span class="doc-count">{{ status.doc_count }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div class="filter-item">
        <h4 @click="toggleSection('newspapers')">
          {{ $t("search.filter.newspaper") }}
          <span class="material-symbols-outlined google-icon arrow" :class="{ open: isSectionVisible.newspapers }">
            keyboard_arrow_down
          </span>
        </h4>
        <div v-show="isSectionVisible.newspapers" class="expandable-panel">
          <div class="authors-buttons">
            <div v-if="topNewspapers.length > 0">
              <button v-for="(newspaper, index) in topNewspapers" :key="index"
                @click="selectFilter({ newspaper: newspaper.key })"
                :class="{ active: filters.newspaper === newspaper.key }">
                <span class="author-name">{{ newspaper.key }}</span>
                <span class="doc-count">{{ newspaper.doc_count }}</span>
              </button>
            </div>
            <!--button @click="loadMore('newspapers')" v-if="topNewspapers.length > 0">
              {{ $t("search.filter.moreAuthors") }}
            </button-->
          </div>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div class="filter-item">
        <h4 @click="toggleSection('authors')">
          {{ $t("search.filter.author") }}
          <span class="material-symbols-outlined google-icon arrow" :class="{ open: isSectionVisible.authors }">
            keyboard_arrow_down
          </span>
        </h4>
        <div v-show="isSectionVisible.authors" class="expandable-panel">
          <div class="authors-buttons">
            <div v-if="topAuthors.length > 0">
              <button v-for="(author, index) in topAuthors" :key="index" @click="selectFilter({ author: author.key })"
                :class="{ active: filters.author === author.key }">
                <span class="author-name">{{ author.key }}</span>
                <span class="doc-count">{{ author.doc_count }}</span>
              </button>
            </div>
            <button @click="loadMore('authors')" v-if="topAuthors.length > 0">
              {{ $t("search.filter.more") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--div class="filter-section">
      <div class="filter-item">
        <h4 @click="toggleSection('locations')">
          {{ $t("search.filter.locations") }}
          <span class="material-symbols-outlined google-icon arrow" :class="{ open: isSectionVisible.locations }">
            keyboard_arrow_down
          </span>
        </h4>
        <div v-show="isSectionVisible.locations" class="expandable-panel">
          <div class="authors-buttons">
            <div v-if="topLocations.length > 0">
              <button v-for="(location, index) in topLocations" :key="index"
                @click="selectFilter({ location: location.key })"
                :class="{ active: filters.location === location.key }">
                <span class="author-name">{{ location.key }}</span>
                <span class="doc-count">{{ location.doc_count }}</span>
              </button>
            </div>
            <button @click="loadMore('locations')" v-if="topLocations.length > 0">
              {{ $t("search.filter.more") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div class="filter-item">
        <h4 @click="toggleSection('persons')">
          {{ $t("search.filter.persons") }}
          <span class="material-symbols-outlined google-icon arrow" :class="{ open: isSectionVisible.persons }">
            keyboard_arrow_down
          </span>
        </h4>
        <div v-show="isSectionVisible.persons" class="expandable-panel">
          <div class="authors-buttons">
            <div v-if="topPersons.length > 0">
              <button v-for="(person, index) in topPersons" :key="index" @click="selectFilter({ person: person.key })"
                :class="{ active: filters.person === person.key }">
                <span class="author-name">{{ person.key }}</span>
                <span class="doc-count">{{ person.doc_count }}</span>
              </button>
            </div>
            <button @click="loadMore('persons')" v-if="topPersons.length > 0">
              {{ $t("search.filter.more") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-section">
      <div class="filter-item">
        <h4 @click="toggleSection('organizations')">
          {{ $t("search.filter.organizations") }}
          <span class="material-symbols-outlined google-icon arrow" :class="{ open: isSectionVisible.organizations }">
            keyboard_arrow_down
          </span>
        </h4>
        <div v-show="isSectionVisible.organizations" class="expandable-panel">
          <div class="authors-buttons">
            <div v-if="topOrganizations.length > 0">
              <button v-for="(organization, index) in topOrganizations" :key="index"
                @click="selectFilter({ organization: organization.key })"
                :class="{ active: filters.organizations === organization.key }">
                <span class="author-name">{{ organization.key }}</span>
                <span class="doc-count">{{ organization.doc_count }}</span>
              </button>
            </div>
            <button @click="loadMore('organizations')" v-if="topOrganizations.length > 0">
              {{ $t("search.filter.more") }}
            </button>
          </div>
        </div>
      </div>
    </div-->
    <div class="filter-section">
      <div class="filter-item">
        <h4 @click="toggleSection('language')">
          {{ $t("search.filter.language") }}
          <span class="material-symbols-outlined google-icon arrow" :class="{ open: isSectionVisible.language }">
            keyboard_arrow_down
          </span>
        </h4>
        <div v-show="isSectionVisible.language" class="expandable-panel">
          <div class="authors-buttons">
            <div v-if="topStatuses.length > 0">
              <button v-for="(language_code, index) in topLanguages" :key="index" @click="selectFilter({ language_code: language_code.key })"
                :class="{ active: filters.language_code === language_code.key }">
                <span class="author-name">{{ getLanguageName(language_code.key) }}</span>
                <span class="doc-count">{{ language_code.doc_count }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  <PaginatedDialog
    v-if="showDialog"
    :filterType="dialogFilterType"
    :showDialog="showDialog"
    @close="closeDialog"
  />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useSearchStore } from '../stores/search';
import { useUserStore } from "../stores/user";
import Datepicker from '@vuepic/vue-datepicker';
import PaginatedDialog from './PaginatedDialog.vue';
import '@vuepic/vue-datepicker/dist/main.css';
import { useRouter } from "vue-router";
import { INITIAL_FILTERS } from "../constants";
import { getLanguageName } from "../utils"
import SearchHistogram from "./SearchHistogram.vue";
import { useContractStore } from "../stores/contract";

export default defineComponent({
  name: "FiltersCard",
  components: {
    Datepicker,
    PaginatedDialog,
    SearchHistogram,
  },
  setup() {
    const userStore = useUserStore();
    const store = useSearchStore();
    const contractStore = useContractStore();
    const route = useRoute();
    const isAuthenticated = computed(() => contractStore.isAuthenticated);
    const dateRange = ref([INITIAL_FILTERS.publish_date_start, INITIAL_FILTERS.publish_date_end]);
    const router = useRouter();
    const topAuthors = computed(() => store.topAuthors);
    const topLanguages = computed(() => store.topLanguages);
    const topNewspapers = computed(() => store.topNewspapers);
    const topStatuses = computed(() => store.topStatuses);
    const topLocations = computed(() => store.topLocations);
    const topPersons = computed(() => store.topPersons);
    const topOrganizations = computed(() => store.topOrganizations);
    const newspapers = computed(() => store.allNewspapers);
    const allLocations = computed(() => store.allLocations);
    const allPersons = computed(() => store.allPersons);
    const allOrganizations = computed(() => store.allOrganizations);
    const filters = computed(() => store.filters)
    const showDialog = ref(false);
    const dialogFilterType = ref("");
    const appliedFilters = computed(() => {
      const filterList = [];
      if (filters.value.newspaper) {
        filterList.push({
          label: "Newspaper",
          value: filters.value.newspaper,
          key: "newspaper",
        });
      }
      if (filters.value.author) {
        filterList.push({
          label: "Author",
          value: filters.value.author,
          key: "author",
        });
      }
      if (filters.value.language_code) {
        filterList.push({
          label: "Language",
          value: getLanguageName(filters.value.language_code),
          key: "language_code",
        });
      }

      if (filters.value.status) {
        filterList.push({
          label: "Status",
          value: filters.value.status,
          key: "status",
        });
      }
      //if (filters.value.locations) {
      //  filterList.push({
      //    label: "Location",
      //    value: filters.value.locations,
      //    key: "locations",
      //  });
      //}
      return filterList;
    });

    const removeAllFilters = () => {
      store.resetFilters();
      store.applyFilters()
      router.replace({
        path: router.currentRoute.value.path,
        query: {},
      });
      window.scrollTo(0, 0);
    };

    const isSectionVisible = ref({
      dates: true,
      newspapers: true,
      language: true,
      authors: true,
      status: true
      //locations: false,
      //persons: false,
      //organizations: false,
    });

    const toggleSection = (section) => {
      isSectionVisible.value[section] = !isSectionVisible.value[section];
    };

    const toggleSecondaryNav = () => {
      if (route.name === "search") {
        userStore.toggleSecondaryNav();
      }
    };

    const selectFilter = (newFilters) => {
      store.applyFilters({ ...filters.value, ...newFilters });
      const updatedQuery = {
        ...router.currentRoute.value.query,
        ...newFilters
      };
      router.replace({ query: updatedQuery });
      window.scrollTo(0, 0);
    };

    const loadMore = (filterType) => {
      dialogFilterType.value = filterType;
      showDialog.value = true;
    };

    const closeDialog = () => {
      showDialog.value = false;
    };

    const setDateRange = (range) => {
      const now = new Date();
      let startDate = null;
      let endDate = new Date();

      switch (range) {
        case 'last24hours':
          startDate = new Date(now.setDate(now.getDate()));
          break;
        case 'lastWeek':
          startDate = new Date(now.setDate(now.getDate() - 7));
          break;
        case 'lastMonth':
          startDate = new Date(now.setMonth(now.getMonth() - 1));
          break;
        case 'lastYear':
          startDate = new Date(now.setFullYear(now.getFullYear() - 1));
          break;
        default:
          return;
      }
      dateRange.value = [startDate, endDate];
    };

    const initializeFiltersFromRoute = () => {
      //if (!isAuthenticated.value) return;
      const queryParams = route.query;
      const filterUpdates = {};
      if (queryParams.author) {
        filterUpdates.author = queryParams.author;
      }
      if (queryParams.newspaper) {
        filterUpdates.newspaper = queryParams.newspaper;
      }
      if (queryParams.page) {
        store.updatePage(parseInt(queryParams.page));
      }
      if (queryParams.sort) {
        filterUpdates.sort = queryParams.sort;
      }
      if (Object.keys(filterUpdates).length > 0) {
        store.applyFilters(filterUpdates);
      }
    };

    watch(isAuthenticated, (newValue) => {
      if (newValue) {
        initializeFiltersFromRoute();
        store.applyFilters();
      }
    });

    watch(dateRange, (newRange) => {
      if (Array.isArray(newRange) && newRange[0] && newRange[1]) {
        const startDate = newRange[0].toISOString().slice(0, 10);
        const endDate = newRange[1].toISOString().slice(0, 10);
        store.applyFilters({
          publish_date_start: startDate,
          publish_date_end: endDate,
        });
        console.log(startDate, endDate)
      } else if (newRange === null) {
        store.applyFilters({
          publish_date_start: null,
          publish_date_end: null,
        });
      }
    });

    const handleCitySelection = ({ cityName }) => {
      store.applyFilters({ locations: [cityName] })
    }

    watch(
      () => filters.value.sort,
      (newSort) => {
        //if (isAuthenticated.value) {
        store.applyFilters({ sort: newSort });
        router.replace({
          query: {
            ...router.currentRoute.value.sort,
            sort: newSort,
          },
        });
        //}
      }
    );

    onMounted(async () => {
      //await contractStore.authenticateWithArconnect();
      //if (isAuthenticated.value) {
      initializeFiltersFromRoute();
      //}
    });

    return {
      allLocations,
      allPersons,
      allOrganizations,
      appliedFilters,
      dateRange,
      dialogFilterType,
      filters,
      isSectionVisible,
      newspapers,
      showDialog,
      topAuthors,
      topLanguages,
      topNewspapers,
      topStatuses,
      topLocations,
      topPersons,
      topOrganizations,
      closeDialog,
      loadMore,
      removeAllFilters,
      selectFilter,
      setDateRange,
      toggleSection,
      getLanguageName,
      toggleSecondaryNav
    };
  },
});
</script>

<style scoped>
:deep(.dp__input_wrap) {
  padding: 0;
  margin-bottom: 0.375rem;
}

:deep(.dp__input) {
  padding: 0 0.375rem;
}

:deep(.dp__input) {
  font-family: 'Alegreya', serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1rem;
  height: 1.5rem;
  margin: 0;
}

:deep(.dp__menu_inner) {
  font-family: 'Alegreya', serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}

:deep(.dp__action_button) {
  font-family: 'Alegreya', serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
}

:deep(.dp__action_row) {
  font-family: 'Alegreya', serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
}

:deep(.dp__input_icon) {
  display: none;
}

.date-shortcut-buttons {
  display: flex;
  width: 100;
  justify-content: space-between;
  padding-bottom: 0.375rem;
}

.shortcut-btn {
  border: 0.5px solid #ccc;
}

.shortcut-btn small {
  font-weight: 400;
}

.shortcut-btn:hover {
  background-color: #d0d0d0;
}


.filter-header {
  padding: 0 .375rem;
}

.filter-item {
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid #ccc;
  padding-bottom: 0.375rem;
  gap: .8rem;
}

.filters-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: .5rem .8rem;
  box-sizing: border-box;
  gap: 0.375rem;
  z-index: 1000;
}
.filters-card::-webkit-scrollbar {
  width: 5px;
}

.filters-header {
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem
}

.filter-item h4 {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0.375rem;
}

.google-icon {
  color: rgb(34, 35, 38);
  font-size: 1.25rem;
  font-weight: 400;
}

.arrow {
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.expandable-panel {
  transition: max-height 0.3s ease-in-out;
}

.authors-buttons {
  display: flex;
  flex-direction: column;
}

.authors-buttons button {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem;
  border: 0px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.authors-buttons .author-name {
  flex-grow: 1;
  text-align: left;
}

.authors-buttons .doc-count {
  margin-left: auto;
  text-align: right;
  font-weight: bold;
}

.authors-buttons button:hover {
  background-color: #e0e0e0;
}

.authors-buttons button.active {
  background-color: #f0f0f0;
}

.header-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border-radius: 1.5rem;
  border: 0;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative;
  padding: 0 .75rem;
}

.header-button:hover {
  background-color: var(--link-color-active-bg); /* Dark background on hover */
}

.header-button:hover .google-icon {
  transition: background-color 0.3s ease, filter 0.3s ease;
  filter: invert(1);
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-family: "Alegreya", serif;
  font-weight: 400;
}

@media (max-width: 1280px) {
  .filters-card{
    width: 100%;
    height: fit-content;
    margin: auto;
    padding: 0rem 6.25%;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  .filter-section{
    display: flex;
    min-width: calc((100% - 3rem)/3);
  }
  .filter-item{
    width: 100%;
  }

  .filter-header{
    padding: 0 .75rem;
  }
}

@media (max-width: 720px) {
  .filters-card{
    padding: 0 1.5rem;
  }

  .filter-section{
    display: flex;
    min-width: calc(50% - .75rem);
  }

}
</style>
import { defineStore } from "pinia";
import axios from "axios";
import { getURLFromPieces } from "../utils";
import {
  ALL_NEWSPAPERS_ENDPOINT,
  ALL_ENTITIES_ENDPOINT,
  PAGINATED_AUTHORS_ENDPOINT,
  RELATED_NEWS_ENDPOINT,
  SEARCH_ENDPOINT,
  SUGGEST_ENDPOINT,
  INITIAL_FILTERS,
} from "../constants";
import { toRaw } from "vue";
import { useUserStore } from "./user";
import { useContractStore } from "./contract";
import Plotly from "plotly.js-basic-dist-min";

export const useSearchStore = defineStore("search", {
  state: () => ({
    aggregations: null,
    allAuthors: [],
    totalAuthorsCount: 0,
    allNewspapers: [],
    allLocations: [],
    allPersons: [],
    allOrganizations: [],
    city_counts: [],
    currentPage: 0,
    filters: { ...INITIAL_FILTERS },
    paginatedAuthors: [],
    results: {},
    suggestions: [],
    topAuthors: [],
    topLanguages: [],
    topNewspapers: [],
    topStatuses: [],
    topLocations: [],
    topPersons: [],
    topOrganizations: [],
    totalPages: 0,
    totalResults: 0,
    plotly: Plotly,
  }),

  getters: {
    getAggregations: (state) => state.aggregations,
    getAllNewspapers: (state) => state.allNewspapers,
    getAllAuthors: (state) => state.allAuthors,
    getTotalAuthorsCount: (state) => state.totalAuthorsCount,
    getAllLocations: (state) => state.allLocations,
    getAllPersons: (state) => state.allPersons,
    getAllOrganizations: (state) => state.allOrganizations,
    getCurrentPage: (state) => state.currentPage,
    getFilters: (state) => state.filters,
    getPaginatedAuthors: (state) => state.paginatedAuthors,
    getResultsByPage: (state) => (page) => state.results[page] || [],
    getSuggestions: (state) => state.suggestions,
    getTopAuthors: (state) => state.topAuthors,
    getTopLanguages: (state) => state.topLanguages,
    getTopNewspapers: (state) => state.topNewspapers,
    getTopStatuses: (state) => state.topStatuses,
    getTopLocations: (state) => state.topLocations,
    getTopPersons: (state) => state.topPersons,
    getTopOrganizations: (state) => state.topOrganizations,
    getTotalPages: (state) => state.totalPages,
    getTotalResults: (state) => state.totalResults,
  },

  actions: {
    updatePage(page) {
      this.currentPage = page;
    },
    setTotalPages(total) {
      this.totalPages = Math.ceil(total / 10);
    },
    setResults(page, results) {
      this.results[page] = results;
    },
    setAggregations(aggregations) {
      this.aggregations = aggregations;
      if (aggregations?.top_languages) {
        this.topLanguages = aggregations.top_languages.map((bucket) => ({
          key: bucket.key,
          doc_count: bucket.doc_count,
        }));
      } else {
        this.topLanguages = [];
      }
      if (aggregations?.top_authors) {
        this.topAuthors = aggregations.top_authors.map((bucket) => ({
          key: bucket.key,
          doc_count: bucket.doc_count,
        }));
      } else {
        this.topAuthors = [];
      }
      if (aggregations?.top_newspapers) {
        this.topNewspapers = aggregations.top_newspapers.map((bucket) => ({
          key: bucket.key,
          doc_count: bucket.doc_count,
        }));
      } else {
        this.topNewspapers = [];
      }
      if (aggregations?.top_statuses) {
        this.topStatuses = aggregations.top_statuses.map((bucket) => ({
          key: bucket.key,
          doc_count: bucket.doc_count,
        }));
      } else {
        this.topStatuses = [];
      }
      if (aggregations?.top_locations) {
        this.topLocations = aggregations.top_locations.map((bucket) => ({
          key: bucket.key,
          doc_count: bucket.doc_count,
        }));
      } else {
        this.topLocations = [];
      }
      if (aggregations?.top_persons) {
        this.topPersons = aggregations.top_persons.map((bucket) => ({
          key: bucket.key,
          doc_count: bucket.doc_count,
        }));
      } else {
        this.topPersons = [];
      }
      if (aggregations?.top_organizations) {
        this.topOrganizations = aggregations.top_organizations.map(
          (bucket) => ({
            key: bucket.key,
            doc_count: bucket.doc_count,
          }),
        );
      } else {
        this.topOrganizations = [];
      }
    },
    setTotalResults(total) {
      this.totalResults = total;
    },
    setAllAuthors(authors) {
      this.allAuthors = authors;
    },
    setAllNewspapers(newspapers) {
      this.allNewspapers = newspapers;
    },
    setAllLocations(locations) {
      this.allLocations = locations;
    },
    setAllPersons(persons) {
      this.allPersons = persons;
    },
    setAllOrganizations(organizations) {
      this.allOrganizations = organizations;
    },
    setFilters(filters) {
      this.filters = { ...this.filters, ...filters };
    },
    setSuggestions(suggestions) {
      this.suggestions = suggestions;
    },
    resetFilters() {
      this.filters = { ...INITIAL_FILTERS };
    },

    async applyFilters(filters) {
      this.setFilters(filters);
      this.results = {};
      await this.fetchSearchResults();
    },

    async fetchRelatedNews(newsId) {
      const userStore = useUserStore();
      userStore.setIsLoading(true);
      try {
        const response = await axios.get(
          getURLFromPieces(RELATED_NEWS_ENDPOINT),
          { params: { newsId } },
        );
        console.log(response.data.related_news);
        if (response.data.related_news) {
          return response.data.related_news;
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching related news: ", error);
        userStore.setServerError(error);
      } finally {
        userStore.setIsLoading(false);
      }
    },

    async fetchPaginatedAuthors(page) {
      const userStore = useUserStore();
      userStore.setIsLoading(true);
      try {
        const response = await axios.get(
          getURLFromPieces(PAGINATED_AUTHORS_ENDPOINT),
          { params: { page } },
        );
        this.setAllAuthors(response.data.authors);
        this.totalAuthorsCount = response.data.total;
      } catch (error) {
        console.error(error);
        userStore.setServerError(error);
      } finally {
        userStore.setIsLoading(false);
      }
    },

    async fetchNewspapersFromElastic() {
      const userStore = useUserStore();
      userStore.setIsLoading(true);
      try {
        const response = await axios.get(
          getURLFromPieces(ALL_NEWSPAPERS_ENDPOINT),
        );
        this.setAllNewspapers(response.data.newspapers);
      } catch (error) {
        console.error(error);
        userStore.setServerError(error);
      } finally {
        userStore.setIsLoading(false);
      }
    },

    async fetchEntitiesFromElastic() {
      const userStore = useUserStore();
      userStore.setIsLoading(true);
      try {
        const response = await axios.get(
          getURLFromPieces(ALL_ENTITIES_ENDPOINT),
          { params: this.filters },
        );
        const data = response.data;
        this.setAllLocations(data.locations);
        this.setAllPersons(data.persons);
        this.setAllOrganizations(data.organizations);
      } catch (error) {
        console.error(error);
        userStore.setServerError(error);
      } finally {
        userStore.setIsLoading(false);
      }
    },

    async fetchSuggestions() {
      const userStore = useUserStore();
      userStore.setIsLoading(true);
      try {
        const query = this.filters.query || "";
        const response = await axios.get(getURLFromPieces(SUGGEST_ENDPOINT), {
          params: { query },
        });
        this.setSuggestions(response.data || []);
        return response.data.suggestions;
      } catch (error) {
        console.error("Error fetching suggestions: ", error);
        throw error;
      } finally {
        userStore.setIsLoading(false);
      }
    },

    async fetchSearchResults() {
      const userStore = useUserStore();
      const contractStore = useContractStore();
      if (!contractStore.isAuthenticated) {
        return null;
      }
      userStore.setIsLoading(true);
      try {
        let query = toRaw(this.filters);
        query = Object.fromEntries(
          Object.entries(query).filter(([_, value]) => {
            return (
              value !== "" &&
              value !== null &&
              !(Array.isArray(value) && value.length === 0)
            );
          }),
        );
        const response = await axios.post(
          getURLFromPieces(SEARCH_ENDPOINT),
          query,
        );
        if (response.data.aggregations) {
          this.setAggregations(response.data.aggregations);
        }
        const totalResults = response.data.total || 0;
        this.setTotalResults(totalResults);
        const pageSize = 10;
        this.setTotalPages(Math.ceil(totalResults / pageSize));
        const results = response.data.hits.map((hit) => hit._source);
        for (let i = 0; i < results.length; i += pageSize) {
          const chunk = results.slice(i, i + pageSize);
          const page = Math.floor(i / pageSize) + 1;
          this.setResults(page, chunk);
        }
        return response.data;
      } catch (error) {
        console.error(error);
        userStore.setServerError(error);
      } finally {
        userStore.setIsLoading(false);
      }
    },

    async fetchPageOnly(page) {
      const userStore = useUserStore();
      try {
        const query = {
          page: String(page),
          sort: String(this.filters.sort),
        };
        const response = await axios.post(
          getURLFromPieces(SEARCH_ENDPOINT),
          query,
        );
        const results = response.data.hits.map((hit) => hit._source);
        const pageSize = 10;
        for (let i = 0; i < results.length; i += pageSize) {
          const chunk = results.slice(i, i + pageSize);
          const currentPage = Math.floor(i / pageSize) + page;
          this.setResults(currentPage, chunk);
        }
        return response.data;
      } catch (error) {
        console.error("Error fetching page:", error);
        userStore.setServerError(error);
      }
    },
  },
});

import axios from "axios";
import { contractId } from "../constants.js";
import { getURLFromPieces, isHeaderOK } from "../utils";
import { AUTHENTICATE_ENDPOINT, CHALLENGE_ENDPOINT } from "../constants";
import { defineStore } from "pinia";
import { useUserStore } from "./user.js";
import { WarpFactory } from "warp-contracts";

export const useContractStore = defineStore("contract", {
  state: () => {
    return {
      headers: null,
      contractState: [],
      contractId: contractId,
      articles: [],
      warp: null,
      contract: null,
      wallet: null,
      currentAccount: {},
      isConnected: false,
      isAuthenticated: false,
      userStore: useUserStore(),
    };
  },

  getters: {
    getContractState: (state) => state.contract,
    getArticlesState: (state) => state.articles,
    getCurrentAccount: (state) => state.currentAccount,
    getIsConnected: (state) => state.isConnected,
    getHeaders: (state) => state.headers,
  },

  actions: {
    setCurrentAccount(account) {
      this.currentAccount = account;
    },
    setConnectedStatus(status) {
      this.isConnected = status;
    },
    setAuthentication(authenticate) {
      this.isAuthenticated = authenticate;
    },
    setHeaders(headers) {
      this.headers = headers;
    },
    async initWarp() {
      try {
        const { WarpFactory } = await import("warp-contracts");
        const environment = process.env.NODE_ENV;
        this.warp =
          environment === "development"
            ? await WarpFactory.forLocal(1984)
            : await WarpFactory.forMainnet();
      } catch (error) {
        console.error("Error initializing Warp:", error);
      }
    },

    async getContract() {
      try {
        if (!this.warp) await this.initWarp();
        this.contract = await this.warp.contract(this.contractId);
        const { cachedValue } = await this.contract.readState();
        this.articles = this.extractValidArticles(cachedValue);
      } catch (error) {
        console.error("Error fetching contract:", error);
      }
    },

    extractValidArticles(cachedValue) {
      const transactionIds = Object.keys(cachedValue.validity);
      return cachedValue.state.articles
        .map((article, index) => ({
          ...article,
          transactionId: cachedValue.validity[transactionIds[index]]
            ? transactionIds[index]
            : null,
        }))
        .filter((article) => article.transactionId !== null);
    },

    async connectArconnect() {
      try {
        this.userStore.setIsLoading(true);
        if (window.arweaveWallet) {
          await window.arweaveWallet.connect(["ACCESS_ADDRESS", "SIGNATURE"]);
          const account = await window.arweaveWallet.getActiveAddress();
          this.setConnectedStatus(true);
          this.currentAccount = { address: account };
          //if (!this.contract) {
          //  await this.getContract();
          //}
          //await this.contract.connect("use_wallet");
        } else {
          throw new Error(
            "ArConnect is not available. Please install the extension.",
          );
        }
      } catch (error) {
        console.error("Error connecting with ArConnect:", error);
        this.userStore.setServerError(error.message);
      } finally {
        this.userStore.setIsLoading(false);
      }
    },

    async connectArweaveApp() {
      try {
        this.userStore.setIsLoading(true);
        const { ArweaveWebWallet } = await import("arweave-wallet-connector");
        const arweaveWebWallet = new ArweaveWebWallet({
          name: "Karakutu",
        });
        await arweaveWebWallet.setUrl("https://arweave.app");
        await arweaveWebWallet.connect();
        this.wallet = arweaveWebWallet;
        if (arweaveWebWallet.connected) {
          this.userStore.setConnectedStatus(true);
          this.wallet = arweaveWebWallet;
          if (!this.contract) {
            await this.getContract();
          }
          await this.contract.connect("use_wallet");
          const account = arweaveWebWallet.address;
          this.userStore.currentAccount = { address: account };
        } else {
          throw new Error("Wallet connection failed");
        }
      } catch (error) {
        this.userStore.setServerError(
          error.message || "Error connecting wallet",
        );
        console.error("Error connecting wallet:", error);
      } finally {
        this.userStore.setIsLoading(false);
      }
    },

    async disconnectWallet() {
      try {
        if (window.arweaveWallet) {
          await window.arweaveWallet.disconnect();
          this.isConnected = false;
          this.currentAccount = {};
        } else {
          console.warn("ArConnect is not available.");
        }
      } catch (error) {
        console.error("Error disconnecting wallet:", error);
      }
    },

    async postArticle(payload) {
      try {
        if (!this.warp) {
          await this.initWarp();
        }
        if (!this.contract) {
          await this.getContract();
        }
        if (window.arweaveWallet) {
          const connectedAddress =
            await window.arweaveWallet.getActiveAddress();
          if (!connectedAddress) {
            throw new Error(
              "ArConnect wallet not connected. Please connect your wallet.",
            );
          }
          await this.contract.connect("use_wallet");
          const interactionData = {
            function: "postArticle",
            title: payload.title,
            newsText: payload.newsText,
            publishDate: payload.publishDate,
            newspaperName: payload.newspaperName,
            authors: payload.authors,
            columnTitle: payload.columnTitle || "",
            description: payload.description || "",
            metadataInfo: payload.metadataInfo || "{}",
          };
          const result = await this.contract.writeInteraction(interactionData);
          const transactionId = result.originalTxId;
          console.log("Transaction ID:", transactionId);
        } else {
          throw new Error(
            "Please connect with ArConnect before posting an article.",
          );
        }
      } catch (error) {
        console.error("Error posting article:", error);
      }
    },

    async getPublicJWK() {
      try {
        await window.arweaveWallet.connect(["ACCESS_PUBLIC_KEY"]);
        const publicKey = await window.arweaveWallet.getActivePublicKey();
        const publicJWK = {
          e: "AQAB", // The exponent for Arweave (standard value)
          ext: true, // Key is extractable
          kty: "RSA", // Key type for Arweave
          n: publicKey, // The modulus, which is the public key from ArConnect
        };
        return publicJWK;
      } catch (error) {
        console.error("Failed to get public key:", error);
        throw error;
      }
    },

    async fetchChallenge() {
      const response = await axios.get(getURLFromPieces(CHALLENGE_ENDPOINT), {
        params: { arweave_address: this.currentAccount.address },
      });
      const challenge = response.data.challenge;
      if (!challenge) {
        throw new Error("Failed to retrieve challenge from server.");
      }
      return challenge;
    },

    async signChallenge(challenge) {
      try {
        if (!challenge) {
          throw new Error("Challenge is undefined or null.");
        }
        const challengeBuffer = new TextEncoder().encode(challenge);
        const rawSignature =
          await window.arweaveWallet.signMessage(challengeBuffer);
        const signature = btoa(
          String.fromCharCode(...new Uint8Array(rawSignature)),
        );
        const publicJWK = await this.getPublicJWK();
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${signature}`,
          "X-Arweave-Address": this.currentAccount.address,
          "X-Signature": signature,
          "X-Challenge": challenge,
          "X-Public-JWK": JSON.stringify(publicJWK),
        };
        return headers;
      } catch (error) {
        headers = null;
        console.error("Error signing challenge:", error);
        throw error;
      }
    },

    async verifyChallenge(challenge, signature) {
      const challengeBuffer = new TextEncoder().encode(challenge);
      return window.arweaveWallet.verifyMessage(challengeBuffer, signature);
    },

    async authenticateWithArconnect() {
      try {
        if (!window.arweaveWallet) {
          throw new Error(
            "ArConnect is not available. Please install the extension.",
          );
        }
        if (!this.isConnected) {
          await this.connectArconnect();
        }
        if (!this.currentAccount || !this.currentAccount.address) {
          throw new Error(
            "Current account is not set. Please select an account.",
          );
        }
        const challenge = await this.fetchChallenge();
        const headers = await this.signChallenge(challenge);
        if (!isHeaderOK(headers)) {
          throw new Error("Headers validation failed.");
        }
        const isValidSignature = await this.verifyChallenge(
          challenge,
          headers["X-Signature"],
        );
        if (!isValidSignature) {
          throw new Error("Invalid signature during authentication.");
        }
        const response = await axios.get(
          getURLFromPieces(AUTHENTICATE_ENDPOINT),
          { headers },
        );
        if (response.status === 200 && response.data?.Result === "Success") {
          const cleanHeaders = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.data.Token}`,
          };
          this.setHeaders(cleanHeaders);
          this.setAuthentication(response.data?.Result === "Success");
          return this.isAuthenticated;
        }
        this.setAuthentication(false);
        return false;
      } catch (error) {
        console.error("Failed to authenticate:", error.message);
        this.setAuthentication(false);
        return false;
      }
    },

    async syncWallet() {
      if (window.arweaveWallet) {
        window.addEventListener("arweaveWalletLoaded", async () => {
          const permissions = await window.arweaveWallet.getPermissions();
          if (permissions.length <= 0) {
            await window.arweaveWallet.connect(["ACCESS_ADDRESS"]);
          }
          const account = await window.arweaveWallet.getActiveAddress();
          this.setCurrentAccount({ address: account, type: "ArConnect" });
          this.setConnectedStatus(true);
          window.arweaveWallet.on("accountChanged", (newAccount) => {
            if (!newAccount) {
              this.setConnectedStatus(false);
              this.setCurrentAccount({});
              this.setAuthenticated(false);
            } else {
              this.setCurrentAccount({
                address: newAccount,
                type: "ArConnect",
              });
              this.setAuthenticated(false);
            }
          });
        });
        window.addEventListener("walletSwitch", (e) => {
          const newAddress = e.detail.address;
          if (newAddress) {
            this.setCurrentAccount({
              address: newAccount,
              type: "ArConnect",
            });
            this.setAuthenticated(false);
          } else {
            this.setConnectedStatus(false);
            this.setCurrentAccount({});
            this.setAuthenticated(false);
          }
        });
      } else {
        console.warn("ArConnect is not available.");
      }
    },
  },
});

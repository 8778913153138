<template>
    <div class="overlay">
        <dialog open class="centered-dialog">

            <div class="dialog-card">
                <div class="dialog-header">
                    <h3>Filter by {{ filterType }}</h3>
                    <button @click="closeDialog" class="closer">×</button>
                </div>
                <div v-for="(item, index) in paginatedItems" :key="index" class="dialog-item">
                    <button class="connect-button" @click="selectFilter({ author: item.key })">
                        <h4>{{ item.key }}</h4>
                    </button>
                </div>

                <div class="pagination">
                    <button :disabled="dialogPage === 1" @click="prevPage">Previous</button>
                    <span>{{ dialogPage }} / {{ totalPages }}</span>
                    <button :disabled="dialogPage === totalPages" @click="nextPage">Next</button>
                </div>
            </div>
        </dialog>

    </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useSearchStore } from '../stores/search';
import { useRouter } from "vue-router";

export default {
    name: "PaginatedDialog",
    props: {
        filterType: String,
        showDialog: Boolean,
    },
    setup(props, { emit }) {
        const store = useSearchStore();
        const router = useRouter();
        const filters = computed(() => store.filters)
        const dialogPage = ref(1);
        const totalItems = ref(0);
        const itemsPerPage = ref(10)
        const paginatedItems = ref([]);
        const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage.value));
        const selectFilter = (newFilters) => {
          store.applyFilters({ ...filters.value, ...newFilters });
          const updatedQuery = {
            ...router.currentRoute.value.query,
            ...newFilters
          };
          router.replace({ query: updatedQuery });
          emit("close");
        };
        const fetchData = async () => {
            try {
                if (props.filterType === "authors") {
                    await store.fetchPaginatedAuthors(dialogPage.value);
                    paginatedItems.value = store.allAuthors;
                    totalItems.value = store.totalAuthorsCount;
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const nextPage = () => {
            if (dialogPage.value < totalPages.value) {
                dialogPage.value++;
                fetchData();
            }
        };

        const prevPage = () => {
            if (dialogPage.value > 1) {
                dialogPage.value--;
                fetchData();
            }
        };

        const closeDialog = () => {
            emit("close");
        };

        watch([() => props.showDialog, () => props.filterType], ([newShowDialog, newFilterType]) => {
            if (newShowDialog && newFilterType) {
                dialogPage.value = 1;
                fetchData();
            }
        }, { immediate: true });



        return {
            dialogPage,
            totalPages,
            paginatedItems,
            nextPage,
            prevPage,
            closeDialog,
            selectFilter
        };
    }
};
</script>

<style scoped>
.centered-dialog {
    background: white;
    padding: 0;
    border: none;
    border-radius: 10px;
    z-index: 10000;
}

.dialog-card {
    display: flex;
    flex-direction: column;
    background: white;
    padding: .5rem 1rem;
    border-radius: 1rem;
    text-align: center;
    width: 300px;
    position: relative;
}

.dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
}

.dialog-header h3 {
    margin: 0;
}

.closer {
    background: none;
    border: none;
    font-size: 1rem;
    height: 1.5rem;
    margin: 0;
    cursor: pointer;
    padding: 0;
}

.dialog-item {
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.connect-button {
    display: flex;
    align-content: center;
    padding: .5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    gap: 1rem;
}

.connect-button:hover {
    background-color: #f7f7f9;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
</style>